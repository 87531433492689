<template>
	<div class="publicEmergencies">
		<div class="main-filter">
			<el-form inline :model="formInline" class="demo-form-inline search-form">
				<el-form-item label="事件名称：">
					<el-input v-model.trim="formInline.eventName" clearable placeholder="请输入内容"></el-input>
				</el-form-item>
				<el-form-item label="省：">
					<el-select
						v-model="formInline.economize"
						:disabled="userInfo.regionCode && userInfo.regionCode.length >= 2"
						placeholder="请选择省"
						@change="changeEconomize($event, 1)"
					>
						<el-option v-for="item in economizeList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="市：">
					<el-select
						v-model="formInline.market"
						:disabled="userInfo.regionCode && userInfo.regionCode.length >= 4"
						placeholder="请选择市"
						@change="changeEconomize($event, 2)"
					>
						<el-option v-for="item in marketList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="区县：">
					<el-select
						v-model="formInline.district"
						:disabled="userInfo.regionCode && userInfo.regionCode.length >= 6"
						placeholder="请选择区县"
						@change="changeEconomize($event, 3)"
					>
						<el-option v-for="item in districtList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="乡镇：">
					<el-select
						v-model="formInline.townCode"
						:disabled="userInfo.regionCode && userInfo.regionCode.length >= 9"
						placeholder="请选择"
						@change="changeEconomize($event, 4)"
					>
						<el-option v-for="item in townList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="村：">
					<el-select
						v-model="formInline.villageCode"
						:disabled="userInfo.regionCode && userInfo.regionCode.length >= 12"
						placeholder="请选择"
						
					>
						<el-option v-for="item in villageList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="main-content">
			<div class="main-content-title">
				<p>共有 {{ total }} 条结果</p>
			</div>
			<Table
				:loading="loading"
				:table-data="tableData"
				:total="total"
				:table-head="tableHead"
				:current-page.sync="queryData.current"
				:page-size.sync="queryData.size"
				:is-show-selection="false"
				:operation-column-width="140"
				:showOperation="true"
				@change-page="getList"
				:tableHeight="tableHeight"
			>
				<template #operation="{ scope }">
					<el-button @click="modifyParkInfo(scope.row)" size="mini" type="primary"> 查看 </el-button>
				</template>
			</Table>
		</div>
	</div>
</template>

<script>
export default {
	name: 'healthyText',
	components: {
		Table: () => import('@/components/Table/table'),
	},
	data() {
		return {
			loading: false,
			formInline: {
				eventName: '',
				economize: '',
				market: '',
				district: '',
				townCode: '',
				villageCode: '',
			},
			economizeList: [],
			marketList: [],
			districtList: [],
			townList: [],
			villageList: [],
			queryData: {
				current: 1,
				size: 10,
			},
			total: 0,
			tableData: [],
			tableHead: [
				{
					label: '事件名称',
					prop: 'eventName',
					formatter: (row) => {
						return row.eventName || '-';
					},
				},
				{
					label: '报告类别',
					prop: 'reportType',
					formatter: (row) => {
						return row.reportType || '-';
					},
				},
				{
					label: '突发事件等级',
					prop: 'eventLevel',
					formatter: (row) => {
						return row.eventLevel || '-';
					},
				},
				{
					label: '发生地区',
					prop: 'reportAreaRegionName',
					formatter: (row) => {
						return row.reportAreaRegionName || '-';
					},
				},
				{
					label: '发生场所',
					prop: 'address',
					formatter: (row) => {
						return row.address || '-';
					},
				},
				{
					label: '新报告病例数',
					prop: 'newlyReportedCases',
					formatter: (row) => {
						return row.newlyReportedCases || '-';
					},
				},
				{
					label: '报告人',
					prop: 'speaker',
					formatter: (row) => {
						return row.speaker || '-';
					},
				},
				{
					label: '联系电话',
					prop: 'phoneNumber',
					formatter: (row) => {
						return row.phoneNumber || '-';
					},
				},
				{
					label: '建档时间',
					prop: 'creatTime',
					formatter: (row) => {
						return row.creatTime || '-';
					},
				},
			],
			isShowDetail: false,
			userInfo: {},
		};
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
	mounted() {
		this.getEconomizeList();
		this.userInfo = JSON.parse(sessionStorage.getItem('userInfo')) || {};
		switch (this.userInfo.regionCode.length) {
			case 2:
				this.formInline.economize = this.userInfo.chinaRegionDtoList[0].code != '0' ? this.userInfo.chinaRegionDtoList[0].code : null;
				this.changeEconomize(this.formInline.economize, 1);
				break;
			case 4:
				this.formInline.economize = this.userInfo.chinaRegionDtoList[0].code != '0' ? this.userInfo.chinaRegionDtoList[0].code : null;
				this.changeEconomize(this.formInline.economize, 1);
				this.formInline.market = this.userInfo.chinaRegionDtoList[1].code;
				this.changeEconomize(this.formInline.market, 2);
				break;
			case 6:
				this.formInline.economize = this.userInfo.chinaRegionDtoList[0].code != '0' ? this.userInfo.chinaRegionDtoList[0].code : null;
				this.changeEconomize(this.formInline.economize, 1);
				this.formInline.market = this.userInfo.chinaRegionDtoList[1].code;
				this.changeEconomize(this.formInline.market, 2);
				this.formInline.district = this.userInfo.chinaRegionDtoList[2].code;
				this.changeEconomize(this.formInline.district, 3);
				break;
			case 9:
				this.formInline.economize = this.userInfo.chinaRegionDtoList[0].code != '0' ? this.userInfo.chinaRegionDtoList[0].code : null;
				this.changeEconomize(this.formInline.economize, 1);
				this.formInline.market = this.userInfo.chinaRegionDtoList[1].code;
				this.changeEconomize(this.formInline.market, 2);
				this.formInline.district = this.userInfo.chinaRegionDtoList[2].code;
				this.changeEconomize(this.formInline.district, 3);
				this.formInline.townCode = this.userInfo.chinaRegionDtoList[3].code;
				this.changeEconomize(this.userInfo.regionCode, 4);
				break;
			case 12:
				this.formInline.economize = this.userInfo.chinaRegionDtoList[0].code != '0' ? this.userInfo.chinaRegionDtoList[0].code : null;
				this.changeEconomize(this.formInline.economize, 1);
				this.formInline.market = this.userInfo.chinaRegionDtoList[1].code;
				this.changeEconomize(this.formInline.market, 2);
				this.formInline.district = this.userInfo.chinaRegionDtoList[2].code;
				this.changeEconomize(this.formInline.district, 3);
				this.formInline.townCode = this.userInfo.chinaRegionDtoList[3].code;
				this.changeEconomize(this.userInfo.regionCode, 4);
				this.formInline.villageCode = this.userInfo.chinaRegionDtoList[4].code;
				break;
			default:
				break;
		}
		this.getList();
	},
	methods: {
		onSearch() {
			this.queryData.current = 1;
			this.getList();
		},
		// 获取报告下拉
		getPortList() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: { dictionaryCode: 'EVENT_REPORT_TYPE', size: 9999, current: 1 } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.portList = res.data.collection || [];
						this.portList.unshift({
							id: null,
							title: '全部',
						});
					}
				})
				.catch((e) => {});
		},
		// 获取省数据
		getEconomizeList() {
			this.$http
				.get(this.api['ChinaRegions#index'].href)
				.then((res) => {
					if (res.data && res.data.success) {
						this.economizeList = res.data.collection || [];
						this.economizeList.unshift({
							title: '全部',
							code: '',
						});
					}
				})
				.catch((e) => {});
		},
		// 获取市/区县
		changeEconomize(data, type) {
			switch (type) {
				case 1:
					this.formInline.market = null;
					this.formInline.district = null;
					this.formInline.townCode = null;
					this.formInline.villageCode = null;
					this.marketList = [];
					this.districtList = [];
					this.townList = [];
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api['ChinaRegions#index'].href, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.marketList = res.data.collection || [];
								this.marketList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				case 2:
					this.formInline.district = null;
					this.formInline.townCode = null;
					this.formInline.villageCode = null;
					this.districtList = [];
					this.townList = [];
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api['ChinaRegions#index'].href, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.districtList = res.data.collection || [];
								this.districtList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				case 3:
					this.formInline.townCode = null;
					this.formInline.villageCode = null;
					this.townList = [];
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api['ChinaRegions#index'].href, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.townList = res.data.collection || [];
								this.townList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				case 4:
					this.formInline.villageCode = null;
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api['ChinaRegions#index'].href, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.villageList = res.data.collection || [];
								this.villageList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				default:
					break;
			}
		},
		//获取列表
		getList() {
			this.loading = true;
			const { eventName, economize, market, district, townCode, villageCode } = this.formInline;
			let code =
				economize && !market && !district && !townCode && !villageCode
					? economize
					: economize && market && !district && !townCode && !villageCode
					? market
					: economize && market && district && !townCode && !villageCode
					? district
					: economize && market && district && townCode && !villageCode
					? townCode
					: economize && market && district && townCode && villageCode
					? villageCode
					: null;
			let par = {
				eventName,
				regionCode: code,
				contentCode: 'PEMI',
				...this.queryData,
			};
			this.$http
				.get(this.api['PersonForms#index'].href, { params: par })
				.then((res) => {
					if (res.data && res.data.success) {
						let collection = res.data.collection || {};
						this.loading = false;
						if (collection.length == 0) {
							this.tableData = [];
							this.total = 0;
							return;
						}
						collection = collection.map((item) => {
							return {
								...item,
								content: item.content ? JSON.parse(item.content) : {},
							};
						});
						let arr = collection.map((item) => {
							if (JSON.stringify(item.content) !== '{}') {
								return {
									id: item.id,
									eventName: item.content.items[3].items[0].value?.toString() || '-',
									reportType:
										item.content.items[4].items[0].value
											?.map((v) => {
												return v.match(/\d+[,，.、]?\s*(.*)/)[1];
											})
											.toString() || '-',
									eventLevel:
										item.content.items[5].items[0].value
											?.map((v) => {
												return v.match(/\d+[,，.、]?\s*(.*)/)[1];
											})
											.toString() || '-',
									reportAreaRegionName: item.content.items[13].items.map((v) => v.value).join('') || '-',
									address:
										item.content.items[15].items[0].value
											?.map((v) => {
												return v.match(/\d+[,，.、]?\s*(.*)/)[1];
											})
											.toString() || '-',
									newlyReportedCases: item.content.items[19].items[0].value?.toString() || '-',
									speaker: item.content.items[1].items[0].value?.toString() || '-',
									phoneNumber: item.content.items[2].items[0].value?.toString() || '-',
									creatTime: item.content.items[0].items[0].value?.toString() || '-',
								};
							}
						});
						this.tableData = JSON.parse(JSON.stringify(arr)) || [];
						this.total = res.data.pagination.totalItems || 0;
					}
				})
				.catch((e) => {});
		},
		modifyParkInfo(row) {
			this.$router.push({
				path: '/staticTotalView/publicEmergencies/details',
				query: {
					id: row.id,
				},
			});
		},
	},
};
</script>

<style lang="scss" scoped>
/deep/ .search-form {
	.el-select {
		width: 120px;
		.el-input {
			width: 120px;
			.el-input__inner {
				width: 120px;
			}
		}
	}
	.el-input {
		width: 160px;
	}
}
.main-content {
	&-title {
		line-height: 48px;
		display: flex;
		justify-content: space-between;
		.el-button {
			line-height: 32px;
			padding: 0 16px;
			height: 32px;
			margin-top: 8px;
		}
	}
}
</style>